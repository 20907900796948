/* Global Styles */
* {
  box-sizing: border-box;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.bannerlogo {
  max-width: 861px;
  width: 95vw;
  height: auto;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9; /* Light background */
  font-family: Arial, sans-serif;
  color: #333; /* Text color */
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  position: relative;
}

.loading-text::after {
  content: '...';
  animation: dots 1.5s infinite;
}

@keyframes dots {
  0% {
    content: '';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}


/* Navigation Bar */
nav {
  background-color: rgb(0,144,213);
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.nav-arrow {
  position: absolute;
  top: calc(50%)-50%;
  left: 10px;
  color: rgb(92,184,255);
  transition: rotate 0.3s ease;
}

.dropdown:hover .nav-arrow {
  rotate: 90deg;
  transition: rotate 0.3s ease;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 20vw;
  font-size: min(2vw, 16px);
}

nav li {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  white-space: nowrap;
}

nav li a,
.dropbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  background-color: rgb(0,144,213);
  cursor: pointer;
  text-align: center;
  height: 100%;
}

nav li a:hover:not(.mobile-nav li a),
.dropdown:hover {
  background-color: rgb(0, 164, 240);
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 120px;
}

li:nth-child(2) .dropdown {
  min-width: 130px;
}

li:nth-child(3) .dropdown {
  min-width: 165px;
}

li:nth-child(5) .dropdown {
  min-width: 60px;
}

.dropdown-content,
nav li a:last-child:not(:first-child) {
  border-radius: 0px 0px 10px 10px;
}

.dropdown-content {
  opacity: 0%;
  position: absolute;
  background-color: rgb(0,144,213);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  white-space: wrap;
  top: 100%;
  visibility: hidden;
  transition: visibility 0.2s, opacity 0.2s linear;
}

.dropdown-content a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  width: 100%;
  transition: background-color 0.3s ease;
}

.dropdown-content a:hover {
  background-color: rgb(0,164,213);
}

.dropdown:hover .dropdown-content {
  opacity: 100%;
  visibility: visible;
  transition: visibility 0s, opacity 0.2s linear;
}

.dropdown:hover .dropbtn {
  background-color: rgb(0,144,213);
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.logout-button {
  background: none; 
  border: none;
  color: white;
  text-align: center;
  cursor: pointer;
  padding: 12px 16px;
  font-size: inherit;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  transition: background-color 0.3s ease;
  border-radius: 0px 0px 10px 10px;
}

.logout-button:hover {
  background-color: rgb(0,164,213);
  text-decoration: none;
}

.logout-button, .dropdown-content a:last-child {
  border-radius: 0px 0px 10px 10px;
}

.logout-button:hover, .dropdown-content a:last-child:hover {
  background-color: rgb(0,164,213);
  text-decoration: none;
}

/* Mobile NavBar */
.mobile-nav {
  display: none;
  padding: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.nav-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.hamburger {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.mobile-cart {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.mobile-menu {
  width: 100%;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;
  flex-direction: column;
  align-items: center;
}

.mobile-menu.open {
  height: 100vh;
  transition: height 0.3s ease-in;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2vh;
}

.mobile-nav-link {
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.mobile-nav-link a {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 10px 0;
}

.mobile-nav-group {
  width: 100%;
  padding: 5px 0;
  text-align: center;
}

.mobile-nav-group a {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 8px 0;
}

.menu-divider {
  width: 80%;
  border: 1px solid lightgray;
  margin: 10px 0;
}

@media (max-width: 850px) {
  .mobile-nav {
    display: flex;
  }
}

@media (max-width: 400px) {
  .mobile-nav-link {
    padding: 0px;
  }

  .mobile-nav-link {
    padding: 5px;
  }

  .mobile-nav-group {
    padding: 2px;
  }

  .mobile-nav-group a {
    padding: 5px;
  }
}

/* Footer */
.footer {
  background-color: rgb(0,144,213);
  color: white;
  text-align: center;
  height: 150px;
  flex-shrink: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Mobile Footer */
@media screen and (max-width: 768px) {
  .footer {
    background-color: rgb(0,144,213);
    color: white;
    text-align: center;
    padding: 20px 10px;
    height: auto;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
  }

  .footer-links a {
    color: white;
    text-decoration: none;
  }

  .footer-links a:hover {
    text-decoration: underline;
  }
}

/* Container Styles for Different Pages */
.meet-the-team-container,
.shop-container,
.blogpost-container,
.videopost-container,
.agentfind-container,
.contact-container,
.careers-container {
  padding: 20px;
  max-width: 1200px;
  margin: 60px auto;
  text-align: center;
}

/* Landing Page */
.landing-container {
  padding: 20px;
  max-width: 1500px;
  margin: auto auto;
  text-align: center;
}

.catalogue-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
  flex-wrap: wrap;
  transition: transform 0.3s ease;
}

.catalogue-images img {
  width: 100%;
  max-width: 650px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.catalogue-image-link:hover .catalogue-image {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.catalogue-image-link:active .catalogue-image {
  transform: scale(0.95);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  margin: 20px 0;
}

.shift-up {
  transform: translateY(-50px);
}

.landing-navcontent {
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  padding-top: 0;
}

.checktools-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 15px;
  align-items: center;
}

.landing-seedmix-btn {
  margin-top: 15px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-modal-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-modal-btn:hover {
  background-color: #c82333;
}

.open-modal-btn {
  padding: 12px 18px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.open-modal-btn:hover {
  background-color: #0056b3;
  transition: background-color 0.3s ease;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 420px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.close-modal-btn {
  z-index: 1001;
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.close-modal-btn:hover {
  background-color: #c82333;
  transition: background-color 0.3s ease;
}

.pdf-list {
  list-style: none;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f8f8f8;
  padding: 10px;
  margin-bottom: 15px;
}

.pdf-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  transition: background 0.2s ease-in-out;
}

.pdf-list li:last-child {
  border-bottom: none;
}

.pdf-list li:hover {
  background: #f1f1f1;
}

.pdf-list label {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  width: 100%;
  padding: 5px 8px;
  border-radius: 5px;
  transition: background 0.2s ease-in-out;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: #007bff;
  cursor: pointer;
  transform: scale(1.1);
}

.download-btn {
  width: 100%;
  padding: 12px 15px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, transform 0.1s ease-in-out;
}

.download-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.download-btn:hover:not(:disabled) {
  background-color: #0056b3;
  transform: scale(1.03);
}

.pdf-list::-webkit-scrollbar {
  width: 6px;
}

.pdf-list::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 3px;
}

.pdf-list::-webkit-scrollbar-thumb:hover {
  background: #999;
}


@media (max-width: 768px) {
  .catalogue-images {
    flex-direction: column;
    gap: 10px;
  }

  .catalogue-image {
    max-width: 100%;
  }
}

/*Careers*/
.careers-container {
  font-family: "Arial", sans-serif;
  padding: 30px;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.careers-container h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #2b2b2b;
  font-weight: 700;
}

.careers-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.vacancy-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.vacancy-card h2 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #3d3d3d;
  font-weight: 600;
}

.vacancy-card p {
  margin: 10px 0;
  font-size: 1.1em;
  color: #4f4f4f;
  text-align: justify;
}

.vacancy-card ul {
  margin: 15px 0;
  padding-left: 20px;
  text-align: left;
}

.vacancy-card ul li {
  margin-bottom: 8px;
  list-style-type: disc;
  font-size: 1.1em;
  color: #4f4f4f;
}

.apply-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #0056b3;
}

.apply-info {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.apply-info.show {
  max-height: 300px;
  opacity: 1;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.apply-info h3 {
  font-size: 1.5em;
  margin-top: 20px;
  color: #333;
}

.apply-info p,
.apply-info address {
  margin: 5px 0;
  font-size: 1em;
  color: #555;
}

.apply-info a {
  color: #007bff;
  text-decoration: none;
}

.apply-info a:hover {
  text-decoration: underline;
}

/*Agent Finder*/
.agentfind-container h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.agentfind-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.agentsearch-bar-container {
  position: relative;
  flex: 1;
  max-width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agentsearch-bar-container .agentsearch-bar {
  margin-bottom: 0;
}

.agentsearch-bar-container .clear-search-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-14px);
  background: none;
  border: none;
  cursor: pointer;
  color: #aaa;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.agentsearch-bar-container .clear-search-button:hover {
  color: #333;
}

.agentsearch-bar-container .clear-search-button:focus {
  outline: none;
}

.agentfind-selects {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex: 1;
  max-width: 400px;
}

.agentfind-selects select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
}

.agentfind-selects select:hover {
  border-color: #007bff;
}

.agentfind-selects select:focus {
  outline: none;
  border: 2px solid #007bff;
}

#map {
  height: 60vh;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/*About us / meet the team */
.meet-the-team-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.meet-the-team-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #555;
}

.team-upper-image img {
  max-width: 100%;
  max-width: 1000px;
  width: 100%;
  height: 477px;
  object-fit: cover;
  border-radius: 10px;
  margin: 20px 0;
  transition: transform 0.3s ease;
}

.team-upper-image img:hover {
  transform: scale(1.05);
}

.team-lower-images {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.team-lower-images img {
  max-height: 477px;
  flex: 1;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
  width: calc(50% - 20px);
  height: auto;
}

.team-lower-images img:hover {
  transform: scale(1.05);
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 10px 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 0.9rem;
  }

  .team-lower-images img {
    width: 100%;
  }
}

/* Videos */
.videopost-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.videopost-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.videopost-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.videopost-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin: 10px 0;
}

.videopost-date {
  color: #666666;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.videopost-excerpt {
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #444444;
}

.videopost-modal-excerpt {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #444444;
  text-align: center;
  padding: 0 20px;
}

.videopost-watch-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.videopost-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.videopost-watch-button:hover {
  background-color: #0056b3;
}

.videopost-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.videopost-modal-content {
  background: white;
  width: 80vw;
  max-width: 1200px;
  height: 45vw;
  max-height: 65vh;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  position: relative;
}

.videopost-modal-header {
  width: 100%;
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  background-color: #f8f9fa;
}

.videopost-modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.videopost-modal-date {
  color: #666666;
  font-size: 0.9rem;
  margin-top: 5px;
}

.videopost-embed {
  width: 90%;
  height: 90%;
  border: none;
}

.vp-center {
  margin: 0;
}

.videopost-modal-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 0;
}
.videopost-close-modal {
  z-index: 1001;
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videopost-close-modal:hover {
  background-color: #c82333;
}

.videopost-go-back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.videopost-go-back-button:hover {
  background-color: #0056b3;
}

.videopost-search {
  flex: 2;
  margin-right: 10px;
  margin-bottom: 0 !important;
}

.videopost-date-filter {
  flex: 1;
  margin-right: 10px;
}

.videopost-sort {
  flex: 1;
}

.videopost-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.videopost-search,
.videopost-date-filter,
.videopost-sort {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* VIDEOS MOBILE */
@media (max-width: 768px) {
  .videopost-container {
    padding: 10px;
  }

  .videopost-card {
    padding: 15px;
    margin-bottom: 15px;
  }

  .videopost-title {
    font-size: 1.2rem;
  }

  .videopost-date {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .videopost-excerpt {
    font-size: 0.9rem;
    margin: 5px 0;
  }

  .videopost-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .videopost-watch-button {
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  .videopost-modal-content {
    width: 90vw;
    max-width: 100%;
    height: auto;
    max-height: 80vh;
    border-radius: 10px;
  }

  .videopost-modal-header {
    padding: 15px;
  }

  .videopost-modal-title {
    font-size: 1.2rem;
  }

  .videopost-modal-date {
    font-size: 0.8rem;
  }

  .videopost-modal-body {
    padding: 10px;
  }

  .videopost-embed {
    width: 100%;
    height: 56.25vw;
  }

  .videopost-modal-excerpt {
    margin-top: 5px;
    font-size: 0.9rem;
    padding: 10px;
  }

  .videopost-close-modal {
    width: 25px;
    height: 25px;
    top: 10px;
    right: 10px;
  }

  .videopost-go-back-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .videopost-filters {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }

  .videopost-filters input,
  .videopost-filters select {
    width: 100%;
    margin: 0;
    font-size: 1rem;
  }

  .videopost-search,
  .videopost-date-filter,
  .videopost-sort {
    padding: 8px;
    font-size: 0.9rem;
  }
}

/* Blogs */
.blogpost-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}
.blogpost-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.blogpost-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.blogpost-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.blogpost-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin: 10px 0;
}

.blogpost-date {
  color: #666666;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.blogpost-excerpt {
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #444444;
}

.blogpost-read-more-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.blogpost-read-more-button:hover {
  background-color: #0056b3;
}

.blogpost-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.blogpost-modal-content {
  background: white;
  width: 85vw;
  height: 85vh;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  max-height: 90vh;
}

.blogpost-close-modal {
  z-index: 10000;
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.blogpost-close-modal:hover {
  background-color: #c82333;
}

.blogpost-modal-header {
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.blogpost-modal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.blogpost-modal-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, transparent, white);
  z-index: 1;
}

.blogpost-search {
  margin-bottom: 0 !important;
}

.blogpost-modal-text {
  max-width: 800px;
  padding: 20px;
}

.blogpost-modal-title {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
}

.blogpost-modal-date {
  color: gray;
  font-size: 0.9rem;
  margin-bottom: 20px;
  text-align: center;
}

.blogpost-modal-paragraph {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 15px;
  text-align: justify;
}

.blogpost-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.blogpost-search,
.blogpost-date-filter,
.blogpost-sort {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.blogpost-search {
  flex: 2;
  margin-right: 10px;
}

.blogpost-date-filter {
  flex: 1;
  margin-right: 10px;
}

.blogpost-sort {
  flex: 1;
}

.blogpost-go-back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.blogpost-go-back-button:hover {
  background-color: #0056b3;
}

/* MOBILE */
.content {
  padding-top: 50px;
}

@media (max-width: 850px) {
  .bannerlogo {
    width: 90vw;
    max-width: 100%;
    height: auto;
  }
  .shift-up {
    transform: translatey(-25px);
  }
}

@media (max-width: 500px) {
  /* Global Styles */
  html,
  body {
    font-size: 14px;
  }

  .App {
    min-height: 100%;
  }

  .meet-the-team-container,
  .blogs-container,
  .videopost-container,
  .agentfind-container,
  .contact-container,
  .careers-container {
    margin: 20px auto;
    text-align: center;
  }

  /* Navigation Bar */
  nav {
    flex-wrap: wrap;
    padding: 10px;
  }

  nav ul {
    margin: 0 5vw;
    font-size: 12px;
  }

  nav li {
    flex: none;
    font-size: 12px;
  }

  .dropdown {
    min-width: auto;
  }

  .dropdown-content {
    width: 90%;
    font-size: 12px;
  }

  .mobile-nav {
    flex-direction: column;
  }

  .hamburger {
    font-size: 20px;
  }

  /* Landing Page */
  .landing-container {
    padding: 15px;
  }

  .catalogue-images {
    flex-direction: column;
    gap: 10px;
  }

  .catalogue-image,
  .catalogue-image-large {
    max-width: 100%;
  }

  p {
    font-size: 1em;
  }

  /* Meet the Team */
  .team-container {
    flex-direction: column;
    gap: 10px;
  }

  .team-member {
    width: 100%;
  }

  .team-image {
    width: 80px;
    height: 80px;
  }

  .team-member h2 {
    font-size: 1.2em;
  }

  .team-member p {
    font-size: 0.9em;
  }

  /* Blogs */
  .blogpost-container,
  .videopost-container {
    min-height: calc(100vh - 215px);
  }

  .blogpost-filters,
  .videopost-filters {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .blogpost-filters input,
  .blogpost-filters select,
  .videopost-filters input,
  .videopost-filters select {
    width: 100%;
    margin: 0;
  }

  .blogpost-filters input:nth-child(2),
  .videopost-filters input:nth-child(2) {
    text-align: center;
  }
}
